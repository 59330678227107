const aboutMeTextContent = {
  acapitFirst: `
    Projektowanie wnętrz sprawia mi dużo przyjemności, zwłaszcza, że lubię wyzwania. Satysfakcja z pracy jest
    jeszcze większa, gdy klient w zaprojektowanym wnętrzu czuje się bardzo dobrze, tak jakby od zawsze w nim
    mieszkał. W swojej pracy staram się łączyć wygodę i praktyczność, tak żeby wnętrza były ponad czasowe dla
    każdego z inwestorów. Były też miejscem wypoczynku oraz spotkań rodzinnych czy z przyjaciółmi. Dla dzieci
    bezpiecznym miejscem do zabawy, czy sprzyjającym nauce.
    <br />
    Kreując wnętrza zawsze mam na uwadze potrzeby klientów i ich preferencje. Dlatego każde wnętrze jest inne,
    niepowtarzalne, czy to będzie dom, mieszkanie. ART-HOUSE Pracownia projektowania i aranżacji wnętrz, to
    JA.
  `,

  acapitSecond: `
    Lubię wyzwania, a takimi są np: 
    - projekty wnętrz dla osób poruszających się na wózku, dopasowanie ich do
    indywidualnych potrzeb i specjalnych wymagań. <br />
    - Dom z gliny i słomyprasowanej słomy na drewnianym szkielecie wg. tradycyjnych technologii. Niosło to ze 
    sobą nowe doświadczenia w pracy projektowej.<br />
    - Lifting przestrzeni usługowych w starych kamienicach, gdzie często ingerencja nie może być duża z przyczyn
    technicznych <br />
    Projektując lokalne usugowe staram się aby ich wnętrza były przyjazne dla potencjalnych klientó i chętnie do 
    nich wchodzili
    <br />
    ART-HOUSE Pracownia projektowania i aranżacji wnetrz zaprasza.
    Wnętrza dla każdego (do prawej wyrównaj)
  `,

  creamySectionTitle: `
    ART-HOUSE Pracownia projektowania i aranżacji wnętrz - zaprojektuję wnętrza zgodnie z państwa potrzebami
  `,
};

export default aboutMeTextContent;
