const homeSwiperImagesInjection = (function () {
  let init = function (images) {
    const swiperContainer = document.getElementById('swiper-container');
    swiperContainer.innerHTML = injectImages(images);
  };

  let injectImages = (images) => {
    return images
      .map((image) => {
        return `
            <div class="swiper-slide">
                <img src="${image.src}" alt="bcg-header" class="w-full header-img" />
            </div>
    `;
      })
      .join('');
  };

  return { init };
})();

export default homeSwiperImagesInjection;
