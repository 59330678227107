import aboutMeTextInjection from '../modules/about-me-text-injection';
import homeSwiperImagesInjection from '../modules/home-page-gallery-img-injection';
import homePageSwiperImages from '../constans/portfolio-images/home-page-swiper-images';
import Swiper, { Autoplay, Pagination } from 'swiper';
import '../../../../node_modules/swiper/swiper-bundle.css';

window.addEventListener('DOMContentLoaded', () => {
  homeSwiperImagesInjection.init(homePageSwiperImages);
  aboutMeTextInjection.init();

  const swiper = new Swiper('.swiper', {
    modules: [Pagination, Autoplay],
    loop: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
    },
  });
});
