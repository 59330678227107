import aboutMeTextContent from '../constans/about-me-text';

const aboutMeTextInjection = (function () {
  let init = function injectAboutMeTextContent() {
    const aboutMeFirstAcapit = document.getElementById('about-me');
    const aboutMeSecondAcapit = document.getElementById('about-me2');
    const creamySectionTitle = document.getElementById('about-me-creamy-title');

    aboutMeFirstAcapit.innerHTML = aboutMeTextContent.acapitFirst;
    aboutMeSecondAcapit.innerHTML = aboutMeTextContent.acapitSecond;
    if (creamySectionTitle) {
      creamySectionTitle.innerText = aboutMeTextContent.creamySectionTitle;
    }
  };

  return {
    init: init,
  };
})();

export default aboutMeTextInjection;
