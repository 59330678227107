
const homePageSwiperImages = [
    { src: "/assets/projects/Dom_pod_Cycowem,_poddasze/Dom_pod_Cycowem,_poddasze__(1).jpg" },
    { src: "/assets/projects/Dom_pod_Cycowem,_poddasze/Dom_pod_Cycowem,_poddasze__(2).jpg" },
    { src: "/assets/projects/Dom_pod_Cycowem,_poddasze/Dom_pod_Cycowem,_poddasze__(3).jpg" },
    { src: "/assets/projects/Dom_pod_Cycowem,_poddasze/Dom_pod_Cycowem,_poddasze__(4).jpg" },
    { src: "/assets/projects/Dom_pod_Cycowem,_poddasze/Dom_pod_Cycowem,_poddasze__(5).jpg" },
    { src: "/assets/projects/Dom_pod_Cycowem,_poddasze/Dom_pod_Cycowem,_poddasze__(6).jpg" },
    { src: "/assets/projects/Dom_pod_Cycowem,_poddasze/Dom_pod_Cycowem,_poddasze__(7).jpg" },
    { src: "/assets/projects/Dom_pod_Urszulinem_po_liftingu_sypialnia_dla_dziewczynek/Dom_w_Urszulinie_6.jpg" },
    { src: "/assets/projects/Dom_pod_Urszulinem_po_liftingu_sypialnia_dla_dziewczynek/Dom_w_Urszulinie_9.jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie/Dom_w_Urszulinie/Dom_pod_Urszulinem_-_parter_19.jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie/Dom_w_Urszulinie/Dom_pod_Urszulinem_-_parter_21.jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie/Dom_w_Urszulinie/Dom_pod_Urszulinem_-_parter_5.jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie/Dom_w_Urszulinie/Dom_pod_Urszulinem_-_parter_6.jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie/Dom_w_Urszulinie/Dom_pod_Urszulinem_-_parter_9.jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie_sypialnia/Dom_w_Urszulinie/mała_sypialnia_dla_gospodarzy_(1).jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie_sypialnia/Dom_w_Urszulinie/mała_sypialnia_dla_gospodarzy_(2).jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie_sypialnia/Dom_w_Urszulinie/mała_sypialnia_dla_gospodarzy_(3).jpg" },
    { src: "/assets/projects/Dom_w_Urszulinie_sypialnia/Dom_w_Urszulinie/mała_sypialnia_dla_gospodarzy_(4).jpg" },
    { src: "/assets/projects/Dom_w_Żorach/Dom_w_Żorach/pokój_studentki_po_liftingu_w_Żorach_(1).jpg" },
    { src: "/assets/projects/Dom_w_Żorach/Dom_w_Żorach/pokój_studentki_po_liftingu_w_Żorach_(2).jpg" },
    { src: "/assets/projects/Dom_w_Żorach/Dom_w_Żorach/pokój_studentki_po_liftingu_w_Żorach_(3).jpg" },
    { src: "/assets/projects/Dom_w_Żorach/Dom_w_Żorach/Sypialnia_gospodaży_po_liftingu,_dom_w_Żorach__(1).jpg" },
    { src: "/assets/projects/Dom_w_Żorach/Dom_w_Żorach/Sypialnia_gospodaży_po_liftingu,_dom_w_Żorach__(2).jpg" },
    { src: "/assets/projects/kuchnia_z_salonem_w_domu_z_lat_60-tych/Kuchnia_przechodnia_w_domu_z_lat_60_pod_Łęczną_(1).jpg" },
    { src: "/assets/projects/kuchnia_z_salonem_w_domu_z_lat_60-tych/Kuchnia_przechodnia_w_domu_z_lat_60_pod_Łęczną_(2).jpg" },
    { src: "/assets/projects/kuchnia_z_salonem_w_domu_z_lat_60-tych/Kuchnia_przechodnia_w_domu_z_lat_60_pod_Łęczną_(3).jpg" },
    { src: "/assets/projects/kuchnia_z_salonem_w_domu_z_lat_60-tych/Kuchnia_przechodnia_w_domu_z_lat_60_pod_Łęczną_(4).jpg" },
    { src: "/assets/projects/Lublin,_łazienka_w_bloku/Lublin,_łazienka_w_loku__(1).jpg" },
    { src: "/assets/projects/Lublin,_łazienka_w_bloku/Lublin,_łazienka_w_loku__(2).jpg" },
    { src: "/assets/projects/Lublin,_łazienka_w_bloku/Lublin,_łazienka_w_loku__(3).jpg" },
    { src: "/assets/projects/Mała_toaleta_z_prysznicem_w_bloku/Mała_toaleta_z_prysznicem_(1).jpg" },
    { src: "/assets/projects/Mała_toaleta_z_prysznicem_w_bloku/Mała_toaleta_z_prysznicem_(2).jpg" },
    { src: "/assets/projects/Mała_łazienka_z_elementami_drewna/Mała_łazienka_z_elementami_drewna_(1).jpg" },
    { src: "/assets/projects/Mała_łazienka_z_elementami_drewna/Mała_łazienka_z_elementami_drewna_(2).jpg" },
    { src: "/assets/projects/Mała_łazienka_z_elementami_drewna/Mała_łazienka_z_elementami_drewna_(3).jpg" },
    { src: "/assets/projects/Mała_łazienka_z_elementami_drewna/Mała_łazienka_z_elementami_drewna_(4).jpg" },
    { src: "/assets/projects/mieszkanie_dla_osoby_na_wózku_inwalickim/Salon_i_kuchnia_dla_osoby_poruszającej_się_na_wózku__(1).jpg" },
    { src: "/assets/projects/mieszkanie_dla_osoby_na_wózku_inwalickim/Salon_i_kuchnia_dla_osoby_poruszającej_się_na_wózku__(2).jpg" },
    { src: "/assets/projects/mieszkanie_dla_osoby_na_wózku_inwalickim/Łazienka_dla_osoby_poruszajacej_się_na_wózku__(1).jpg" },
    { src: "/assets/projects/mieszkanie_dla_osoby_na_wózku_inwalickim/Łazienka_dla_osoby_poruszajacej_się_na_wózku__(2).jpg" },
    { src: "/assets/projects/mieszkanie_na_obrzeżach_Lublina/Kuchnia_i_salon_II_15.jpg" },
    { src: "/assets/projects/mieszkanie_na_obrzeżach_Lublina/Kuchnia_i_salon_II_6.jpg" },
    { src: "/assets/projects/mieszkanie_na_obrzeżach_Lublina/Mieszkanie_w_Lublinie__(3).jpg" },
    { src: "/assets/projects/mieszkanie_na_obrzeżach_Lublina/Mieszkanie_w_Lublinie__(4).jpg" },
    { src: "/assets/projects/mieszkanie_na_obrzeżach_Lublina/Mieszkanie_w_Lublinie__(6).jpg" },
    { src: "/assets/projects/Mieszkanie_po_generalnym_remoncie_w_Łęcznej/Mieszkanie_po_generalnym_remoncie_w_Łęcznej__(1).jpg" },
    { src: "/assets/projects/Mieszkanie_po_generalnym_remoncie_w_Łęcznej/Mieszkanie_po_generalnym_remoncie_w_Łęcznej__(2).jpg" },
    { src: "/assets/projects/Mieszkanie_po_generalnym_remoncie_w_Łęcznej/Mieszkanie_po_generalnym_remoncie_w_Łęcznej__(3).jpg" },
    { src: "/assets/projects/Mieszkanie_po_generalnym_remoncie_w_Łęcznej/Mieszkanie_po_generalnym_remoncie_w_Łęcznej__(4).jpg" },
    { src: "/assets/projects/Mieszkanie_po_generalnym_remoncie_w_Łęcznej/Mieszkanie_po_generalnym_remoncie_w_Łęcznej__(5).jpg" },
    { src: "/assets/projects/Mieszkanie_po_generalnym_remoncie_w_Łęcznej/Mieszkanie_po_generalnym_remoncie_w_Łęcznej__(6).jpg" },
    { src: "/assets/projects/Mieszkanie_po_generalnym_remoncie_w_Łęcznej/Mieszkanie_po_generalnym_remoncie_w_Łęcznej__(7).jpg" },
    { src: "/assets/projects/mieszkanie_w_Biłgoraju_-_salon/Salon_w_Biłgoraju_I_1.jpg" },
    { src: "/assets/projects/mieszkanie_w_Biłgoraju_-_salon/Salon_w_Biłgoraju_I_3.jpg" },
    { src: "/assets/projects/mieszkanie_w_Biłgoraju_-_salon/Salon_w_Biłgoraju_I_6.jpg" },
    { src: "/assets/projects/mieszkanie_w_Biłgoraju_-_salon/Salon_w_Biłgoraju_I_8.jpg" },
    { src: "/assets/projects/mieszkanie_w_Biłgoraju_-_salon/Salon_w_Biłgoraju_I_9.jpg" },
    { src: "/assets/projects/Mieszkanie_w_Turce_,_salon/Mieszkanie_w_Turce_,_salon/salon_w_mieszkaniu_w_Turce_(1).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Turce_,_salon/Mieszkanie_w_Turce_,_salon/salon_w_mieszkaniu_w_Turce_(2).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Turce_,_salon/Mieszkanie_w_Turce_,_salon/salon_w_mieszkaniu_w_Turce_(5).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(1).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(5).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(2).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(3).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(4).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(10).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(6).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(7).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(8).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Warszawie/Mieszkanie_w_Warszawie_(9).jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej/mieszkanie_w_Łęcznej/Kuchnia_z_salonikiem_II_33_(2).jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej/mieszkanie_w_Łęcznej/Kuchnia_z_salonikiem_II_42_(2).jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej/mieszkanie_w_Łęcznej/Kuchnia_z_salonikiem_II_45_(2).jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej/mieszkanie_w_Łęcznej/Kuchnia_z_salonikiem_II_49.jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej/mieszkanie_w_Łęcznej/Kuchnia_z_salonikiem_II_51.jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej_lifting/2Pokój_dla_młodej_dziewczyny_2.jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej_lifting/Pokój_dla_młodej_dziewczyny_11.jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej_lifting/Pokój_dla_młodej_dziewczyny_12.jpg" },
    { src: "/assets/projects/mieszkanie_w_Łęcznej_lifting/Pokój_dla_młodej_dziewczyny_7.jpg" },
    { src: "/assets/projects/Mieszkanie_w_Łęcznej_po_liftingu/Mieszkanie_po_liftingu_w_Łęcznej__(1).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Łęcznej_po_liftingu/Mieszkanie_po_liftingu_w_Łęcznej__(3).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Łęcznej_po_liftingu/Mieszkanie_po_liftingu_w_Łęcznej__(4).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Łęcznej_po_liftingu/Mieszkanie_po_liftingu_w_Łęcznej__(5).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Łęcznej_po_liftingu/Mieszkanie_po_liftingu_w_Łęcznej__(6).jpg" },
    { src: "/assets/projects/Mieszkanie_w_Łęcznej_po_liftingu/Mieszkanie_po_liftingu_w_Łęcznej__(7).jpg" },
    { src: "/assets/projects/mieszkanie_ze_słomy_i_gliny/Dom_z_gliny,_Łazienka_rodzinna_(1).jpg" },
    { src: "/assets/projects/mieszkanie_ze_słomy_i_gliny/Dom_z_gliny,_Łazienka_rodzinna_(2).jpg" },
    { src: "/assets/projects/mieszkanie_ze_słomy_i_gliny/Dom_z_gliny,_Łazienka_rodzinna_(3).jpg" },
    { src: "/assets/projects/sypialnia_w_domu_jednorodzinnym_liftimg/Dom_pod_Lublinem_-_otwarta_sypialnia_(1).jpg" },
    { src: "/assets/projects/sypialnia_w_domu_jednorodzinnym_liftimg/Dom_pod_Lublinem_-_otwarta_sypialnia_(2).jpg" },
    { src: "/assets/projects/łazienkarodzinna_w_domu_pod_Świdnikiem/Łazienka_rodzinna_(1).jpg" },
    { src: "/assets/projects/łazienkarodzinna_w_domu_pod_Świdnikiem/Łazienka_rodzinna_(2).jpg" },
    { src: "/assets/projects/łazienkarodzinna_w_domu_pod_Świdnikiem/Łazienka_rodzinna_(3).jpg" },
    { src: "/assets/projects/łazienkarodzinna_w_domu_pod_Świdnikiem/Łazienka_rodzinna_(4).jpg" },
    { src: "/assets/projects/łazienkarodzinna_w_domu_pod_Świdnikiem/Łazienka_rodzinna_(5).jpg" },
];

export default homePageSwiperImages;
